import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Created By '}
      <Link color="inherit" href="https://joshaxtell.com/">
         Joshua Axtell aka &quot;Axman&quot;
      </Link>
    </Typography>
  );
}

function Footer() {
//  const { description, title } = props;

  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Container maxWidth="lg">
        <Copyright />
      </Container>
    </Box>
  );
}

//Footer.propTypes = {
//  description: PropTypes.string.isRequired,
//  title: PropTypes.string.isRequired,
//};

export default Footer;
