import React, {useState} from 'react';
import './App.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Switch from '@mui/material/Switch';

const theme = createTheme();

const sections = [{title:'Home', url:'/'}, {title:'About', url:'/about'}, {title:'Classes', url:'/classes'}, {title:'Rules', url:'/rules'}, {title:'Syracuse Arena', url:'/syracuse'}, {title:'Tips and Tricks', url:'/tips'}, {title:'Basic Strategy', url:'/strategy'}, {title: 'Advanced Strategy', url:'/advancedStrategy'}, {title:'Links and Resources',url:'/links'}];

const centerStyle = {
display: "flex",
alignItems: "center",
justifyContent: "center"
}

const Home = () => (
<>
<Header title="Home" sections={sections}/>
  <Container>
	<div>
Welcome to SpaceMarines5.com.  This is a fansite devoted to teaching people how to play the game and improve their skills.  More information about the game is available <a href="http://www.iplaylaserforce.com/games/space-marines-sm5/">here</a>. 
</div>
<div style={centerStyle}>
<iframe width="560" height="315" src="https://www.youtube.com/embed/ACmgHS8IM_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
</div>
  </Container>
</>
);

const About = () => (
<>
<Header title="About" sections={sections}/> 
 <Container><div>
  Space Marines 5 is a common format for many competitive and tournament games for players using Laserforce equipment.  More information about the game is available <a href="http://www.iplaylaserforce.com/games/space-marines-sm5/">here</a>.<br/><br/>
This site was created to help new players learn the game and hone their skills.  It was originally created for players at <a href="https://thefunwarehouse.com">The Fun Warehouse in Syracuse, NY</a> but most of the information should apply to any arena playing Laserforce&apos;s Space Marines 5.
  </div>
</Container>
</>
);

const Classes = () => (
<Container>
<Header title="Classes" sections={sections}/>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
<TableCell>Class</TableCell>
<TableCell>Shots: Initial</TableCell>
<TableCell>Shots: Resupply</TableCell>
<TableCell>Shots: Max</TableCell>
<TableCell>Lives: Initial</TableCell>
<TableCell>Lives: Resupply</TableCell>
<TableCell>Lives: Max</TableCell>
<TableCell>Missiles</TableCell>
<TableCell>Hit Points</TableCell>
<TableCell>Shot Power</TableCell>
<TableCell>Special Ability</TableCell>
<TableCell>Special Points</TableCell>
</TableRow>
</TableHead>
<TableBody>
<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
<TableCell>Commander</TableCell>
<TableCell>30</TableCell>
<TableCell>5</TableCell>
<TableCell>60</TableCell>
<TableCell>15</TableCell>
<TableCell>4</TableCell>
<TableCell>30</TableCell>
<TableCell>5</TableCell>
<TableCell>3</TableCell>
<TableCell>2</TableCell>
<TableCell>Nuke</TableCell>
<TableCell>20</TableCell>
</TableRow>
<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
<TableCell>Heavy Weapons</TableCell>
<TableCell>20</TableCell>
<TableCell>5</TableCell>
<TableCell>40</TableCell>
<TableCell>10</TableCell>
<TableCell>3</TableCell>
<TableCell>20</TableCell>
<TableCell>5</TableCell>
<TableCell>3</TableCell>
<TableCell>3</TableCell>
<TableCell>N/A</TableCell>
<TableCell>N/A</TableCell>
</TableRow>
<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
<TableCell>Scout</TableCell>
<TableCell>30</TableCell>
<TableCell>10</TableCell>
<TableCell>60</TableCell>
<TableCell>15</TableCell>
<TableCell>5</TableCell>
<TableCell>30</TableCell>
<TableCell>N/A</TableCell>
<TableCell>1</TableCell>
<TableCell>1</TableCell>
<TableCell>Rapid Fire</TableCell>
<TableCell>15</TableCell>
</TableRow>
<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
<TableCell>Ammo Carrier</TableCell>
<TableCell>N/A</TableCell>
<TableCell>N/A</TableCell>
<TableCell>N/A</TableCell>
<TableCell>10</TableCell>
<TableCell>3</TableCell>
<TableCell>20</TableCell>
<TableCell>N/A</TableCell>
<TableCell>1</TableCell>
<TableCell>1</TableCell>
<TableCell>Ammo Boost</TableCell>
<TableCell>15</TableCell>
</TableRow>
<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
<TableCell>Medic</TableCell>
<TableCell>15</TableCell>
<TableCell>5</TableCell>
<TableCell>30</TableCell>
<TableCell>20</TableCell>
<TableCell>0</TableCell>
<TableCell>20</TableCell>
<TableCell>N/A</TableCell>
<TableCell>1</TableCell>
<TableCell>1</TableCell>
<TableCell>Life Boost</TableCell>
<TableCell>10</TableCell>
</TableRow>
</TableBody>
</Table>
</TableContainer>
<h4 className="my-4">Scoring</h4>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
<TableCell>Event</TableCell>
<TableCell>Score</TableCell>
<TableCell>Special Points</TableCell>
<TableCell>Lives Lost</TableCell>
</TableRow>
</TableHead>
<TableBody>
<TableRow>
<TableCell>Zap opponent</TableCell>
<TableCell>100</TableCell>
<TableCell>1</TableCell>
<TableCell></TableCell>
</TableRow>
<TableRow>
<TableCell>Zap team</TableCell>
<TableCell>-100</TableCell>
<TableCell></TableCell>
<TableCell></TableCell>
</TableRow>
<TableRow>
<TableCell>Missile opponent</TableCell>
<TableCell>500</TableCell>
<TableCell>2</TableCell>
<TableCell></TableCell>
</TableRow>
<TableRow>
<TableCell>Missile team</TableCell>
<TableCell>-500</TableCell>
<TableCell></TableCell>
<TableCell></TableCell>
</TableRow>
<TableRow>
<TableCell>Destroy target</TableCell>
<TableCell>1001</TableCell>
<TableCell>5</TableCell>
<TableCell></TableCell>
</TableRow>
<TableRow>
<TableCell>Detonate nuke</TableCell>
<TableCell>500</TableCell>
<TableCell></TableCell>
<TableCell></TableCell>
</TableRow>
<TableRow>
<TableCell>Get zapped</TableCell>
<TableCell>-20</TableCell>
<TableCell></TableCell>
<TableCell>1</TableCell>
</TableRow>
<TableRow>
<TableCell>Get Missiled</TableCell>
<TableCell>-100</TableCell>
<TableCell></TableCell>
<TableCell>2</TableCell>
</TableRow>
<TableRow>
<TableCell>Get nuked</TableCell>
<TableCell>0</TableCell>
<TableCell></TableCell>
<TableCell>3</TableCell>
</TableRow>
</TableBody>
</Table>
</TableContainer>
</Container>
);

const Syracuse = () => (
<>
<Header title="Syracuse Arena" sections={sections}/>
<Container>
<div className="text-center">
<picture>
    <source media="(min-width: 750px)" srcSet="./syracuse_map.jpg"/>
    <source media="(min-width: 600px) and (orientation: portrait)" srcSet="./syracuse_map_vertical.jpg"/>
    <source media="(min-width: 175px) and (orientation: portrait)" srcSet="./syracuse_map_small.jpg"/>
    <img src="./syracuse_map.jpg"/>
</picture>
</div>
</Container>
</>
);

const Rules = () => (
<>
<Header title="Rules" sections={sections}/>
<Container>
  <div>
   The complete tournament rules are available for download as a PDF <a href="https://objects-us-east-1.dream.io/lfstatsstatic/Laserforce-Tournament-rules-current-through-June-2018.pdf">here</a>.<br/><br/>Some basic rules to get started:
<ul>
<li>You must be showing at least two sensors when tagging an opponent; your phaser plus at least one other sensor.  If none of your pack sensors are visible to the opponent, including if your phaser is blocking the only sensor that would be visible, that is considered shielding and is a penalty</li>
<li>You must not follow the path of opponents that are deactivated for more than ten feet or three meters.  Targeting an opponent after following their deactivated path is a penalty.</li>
<li>When eliminated, clip your phaser and exit the arena immediately.  Do not interact with the players except to inform them that you&apos;ve been eliminated.</li>
<li>You must be in control at all times.  That means being able to stop within one step, not grabbing walls to round corners, and not jumping sideways.  Not following these can lead to a dangerous play penalty</li>
<li>If someone gets injured, you must not tag them.  If someone is helping someone that is injured, you must not tag either of them.  The injured person has a few seconds to decide if they can continue or let a referee know to stop the game</li>
</ul>
  </div>
</Container>
</>
);

const Links = () => (
<>
<Header title="Links and Resources" sections={sections}/>
<Container>
  <div>
<a href="http://www.iplaylaserforce.com/">IPlayLaserforce.com</a><br/><br/>
<a href="https://thefunwarehouse.com/">The Fun Warehouse</a><br/><br/>
<a href="https://lfstats.com/">LFStats.com</a><br/><br/>
</div>
</Container>
</>
);

const Tips = () => (
<>
<Header title="Tips and Tricks" sections={sections}/>
<Container> <div>
<ul>
<li>Keep your phaser up!</li>
<li>Talk to your teammates to let them know what is going on.</li>
<li>Keep your phaser up!</li>
<li>Learn the callouts for the arena or find a way to communicate where the enemy is.  &quot;On me!&quot; when you&apos;re away from resupply and &quot;In!&quot; when the enemy are moments away from tagging the resupply are a couple of examples.</li>
<li>Keep your phaser up!</li>
<li>Make sure to tag the bases, 3 times each or 1 missle each.</li>
<li>Your phaser; keep it up and ready to tag.</li>
<li>Try not to overwhelm your resupply.  If most of your team is trying to get resupplied and you&apos;re not critical on lives or ammo, head back out to keep the pressure on and return once some of your teammates are done getting resupplied.</li>
</ul>
<h3>Scouts</h3>
<ul>
<li>Rapid fire is available after earning 15 special points.  Once activated it remains active until resupplied.  Ammo can deplete quickly when active.</li>
</ul>
<h3>Commander</h3>
<ul>
<li>Nukes are earned every 20 special points.  Coordinate with your team to avoid getting cancelled by a resupply and for maximum effect.</li>
<li>Look for cycle targets and players or bases to use to reset.  You can reset off a teammate and can be a good idea if it means you can keep a 3-hit down or be able to missle an enemy player.  But don&apos;t use your medic as a reset.</li>
</ul>
<h3>Heavy Weapons</h3>
<ul>
<li>Heavy Weapons: Missles can be used to claim bases.  If you&apos;re not likely to missle enemies then missle the bases to save ammo.</li>
<li>Look for cycle targets and players or bases to use to reset.  You can reset off a teammate and can be a good idea if it means you can keep a 3-hit down or be able to missle an enemy player.  But don&apos;t use your medic as a reset.</li>
</ul>
<h3>Resupply</h3>
<ul>
<li>Boosts affect all other team members, but only if their pack is active when used.</li>
</ul>
<h3>Medic</h3>
<ul>
<li>If the enemy is dropping a nuke, resupply as much of your team as you can before it hits.</li>
</ul>
<h3>Ammo</h3>
<ul>

</ul>

</div>
</Container>
</>
);


const Strategy = () => (
<>
<Header title="Strategy" sections={sections}/>
<Container><Box>
The game is won by the team with the most points.  Your team can get more points than the other team by tagging them more than they tag you.  Tagging down and eliminating the resupplies is nice, but really only works if you&apos;re able to get in and tag people more times than you&apos;re getting tagged.  If you&apos;re repeatedly not able to get into the resupply to tag them down, you might be better off finding a different way to tag the other team more than they tag you or your teammates.
</Box>
<br/>
<Box>
Find a cycle target.  If you can get in to tag down the resupplies and they don&apos;t leave, cycle between them.  Make sure to cycle the three-hit enough so that they stay down.  If you&apos;re near the center base, haven&apos;t captured it yet, and have just tagged down an enemy, you can reset off of the base to tag them again before they re-activate.
</Box>
<br/>
<Box>
Don&apos;t become a cycle target.  If you&apos;re playing resupply and the enemy makes it in and tags you down, get out and prepare to regroup.  Do not stay and give the enemy a chance to cycle you.  If you&apos;re playing forward, be mindful of the center base.  The enemy can reset off of it to continue to cycle you.
</Box>
<br/>
<Box>
Be mindful of your ammo and hit percentage. Make every shot count.  Having a high hit percentage and hit differential (how often you tag versus how often you&apos;re tagged) will help lead you to victory.
</Box>
</Container>
</>
);

const AdvancedStrategy = () => {
const smClasses = { "commander": {"class_name": "Commander", "starting_lives": 15, "max_lives": 30, "lives_resupply": 4, "starting_ammo":30, "max_ammo": 60, "ammo_resupply": 5, "missles": true, "nukes": true, "hit_diff_adjust": .7}, 
"scout": {"class_name": "Scout", "starting_lives": 15, "max_lives": 30, "lives_resupply": 5, "starting_ammo": 30, "max_ammo": 60, "ammo_resupply": 10, "missles": false, "nukes": false, "hit_diff_adjust": 1}};
const [smClass, setSMClass] = useState(smClasses.commander);
const [livesVal, setLivesVal] = useState(smClass.starting_lives);
const [misslesVal, setMisslesVal] = useState(smClass.missles);
const [nukesVal, setNukesVal] = useState(smClass.nukes);
const [ammoVal, setAmmoVal] = useState(smClass.starting_ammo);
const [basesVal, setBasesVal] = useState(true);
const [hitVal, setHitVal] = useState(80);
const [hitDiffVal, setHitDiffVal] = useState(1);
const [lowLivesVal, setLowLivesVal] = useState(10);
const [lowAmmoVal, setLowAmmoVal] = useState(0);
const updateLives = (e, data) => { setLivesVal(data); };
const updateMissles = (e) => { setMisslesVal(e.target.checked); };
const updateNukes = (event) => { setNukesVal(event.target.checked); };
const updateAmmo = (e, data) => { setAmmoVal(data); };
const updateBases = (e) => { setBasesVal(e.target.checked); };
const updateHit = (e, data) => { setHitVal(data); };
const updateHitDiff = (e, data) => { setHitDiffVal(data); };
const updateLowLives = (e, data) => { setLowLivesVal(data); };
const updateLowAmmo = (e, data) => { setLowAmmoVal(data); };


const updateSMClass = (data) => { 
//alert(JSON.stringify(data));
	setSMClass(data); 
	setLivesVal(data.starting_lives);
	setAmmoVal(data.starting_ammo);
	setMisslesVal(data.missles);
	setNukesVal(data.nukes);
//alert(JSON.stringify(smClass));
};

const runCalculations = () => {
const pointsPerZap = 100;
const pointsLostPerZap = 20;
const remainingAmmo = basesVal ? ammoVal - 6 : ammoVal;
const timesShotWhenLowAmmo = Math.ceil((remainingAmmo-lowAmmoVal)*hitVal/100.0/hitDiffVal*smClass.hit_diff_adjust);
const lowOnLives = ((livesVal - timesShotWhenLowAmmo) <= lowLivesVal);
const lowOnLivesOrAmmo = lowOnLives ? "Lives" : "Ammo";
//const shotsLandedWhenLowLives = Math.floor((livesVal-lowLivesVal)*hitDiffVal/smClass.hit_diff_adjust);
const shotsLandedWhenLowLives = Math.min(Math.floor((livesVal-lowLivesVal)*hitDiffVal/smClass.hit_diff_adjust),100);//Math.floor(remainingAmmo*hitVal/100.0));
const shotsTakenWhenLowLives = Math.ceil(shotsLandedWhenLowLives/hitVal*100.0);
const shotsLandedWhenLowAmmo = Math.floor((remainingAmmo-lowAmmoVal)*hitVal/100.0);
const nukesEarned = smClass.class_name == 'Commander' ? Math.floor(((basesVal ? 10 : 0) + (misslesVal ? 10 : 0) + (lowOnLives ? shotsLandedWhenLowLives : shotsLandedWhenLowAmmo))/20) : 0;
const pointsEarnedWhenLow = ((lowOnLives ? shotsLandedWhenLowLives * pointsPerZap : shotsLandedWhenLowAmmo * pointsPerZap) + (basesVal ? 2002 : 0) + (misslesVal ? 2500 : 0) + (nukesVal ? nukesEarned * 500 : 0));
const pointsLostWhenLow = (lowOnLives ? (livesVal - lowLivesVal)*pointsLostPerZap*-1 : timesShotWhenLowAmmo*pointsLostPerZap*-1);
const expectedScoreAtResupply = pointsEarnedWhenLow + pointsLostWhenLow;
return (
<>
<div>
Ammo {basesVal ? 'after getting bases' : ''}: {remainingAmmo}
</div>
<div>
Low on lives or ammo first?: {lowOnLivesOrAmmo}
</div>
{ !lowOnLives ? 
<>
<div>
Lives lost when low on ammo: {timesShotWhenLowAmmo}
</div>
<div>
Shots landed when low on ammo: {shotsLandedWhenLowAmmo}
</div>
</> :
<>
<div>
Shots taken when low on lives: {shotsTakenWhenLowLives}
</div>
<div>
Shots landed when low on lives: {shotsLandedWhenLowLives}
</div>
</>}
{ smClass.nukes ?
<>
<div>
Nukes earned: {nukesEarned}
</div>
</> : ""
}
<div>
Points earned when low {lowOnLivesOrAmmo}:  {pointsEarnedWhenLow}
</div>
<div>
Points lost when low {lowOnLivesOrAmmo}:  {pointsLostWhenLow}
</div>
<div>
Expected score at first resupply: {expectedScoreAtResupply}
</div>
</>);
};

return (
<>
<Header title="Advanced Strategy" sections={sections}/>
<Container>
<Box><h2>Estimate stats at first resupply</h2></Box>
<Box>Choose your class: 
<ButtonGroup>
<Button onClick={() => updateSMClass(smClasses.commander)}>Commander</Button>
<Button onClick={() => updateSMClass(smClasses.scout)}>Scout</Button>
</ButtonGroup>
</Box>
<Box>
Starting Lives: {livesVal}
<Slider 
defaultValue={smClass.starting_lives}
step={smClass.lives_resupply}
marks
min={smClass.starting_lives}
max={smClass.max_lives}
onChange={updateLives}
value={livesVal}
/>
</Box>
<Box>
Starting Ammo: {ammoVal}
<Slider
defaultValue={smClass.starting_ammo}
step={smClass.ammo_resupply}
marks
min={smClass.starting_ammo}
max={smClass.max_ammo}
onChange={updateAmmo}
value={ammoVal}
/>
</Box>
<Box>
Claim Bases:
<Switch onChange={updateBases} defaultChecked={true}/>
Use Missles:
<Switch disabled={!smClass.missles} onChange={updateMissles} checked={misslesVal}/>
Use Nukes:
<Switch disabled={!smClass.nukes} onChange={updateNukes} checked={nukesVal}/>
</Box>
<Box>
Hit Percentage: {hitVal}
<Slider
defaultValue={80}
step={1}
min={1}
max={100}
onChange={updateHit}
value={hitVal}
/>
</Box>
<Box>
Hit Differential: {hitDiffVal} (If unsure set this to 1)
<Slider
defaultValue={1}
step={.1}
min={.1}
max={4}
onChange={updateHitDiff}
value={hitDiffVal}
/>
</Box>
<Box>
Low Lives: {lowLivesVal}
<Slider
defaultValue={10}
step={1}
min={1}
max={livesVal}
onChange={updateLowLives}
value={lowLivesVal}
/>
</Box>
<Box>
Low Ammo: {lowAmmoVal}
<Slider
defaultValue={0}
step={1}
min={0}
max={ammoVal}
onChange={updateLowAmmo}
value={lowAmmoVal}
/>
</Box>
<Box>
<div>{smClass.class_name}: 
</div>
{runCalculations()}
</Box>
</Container>
</>
)};




export default function App() {
  return (
	<div className='App'>
<ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <main>
      <Router>
	<Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/home' element={<Home/>} />
        <Route exact path='/about' element={<About/>} />
	<Route exact path='/classes' element={<Classes/>} />
	<Route exact path='/rules' element={<Rules/>} />
	<Route exact path='/tips' element={<Tips/>} />
        <Route exact path='/strategy' element={<Strategy />} />
	<Route exact path='/advancedStrategy' element={<AdvancedStrategy />} />
	<Route exact path='/syracuse' element={<Syracuse />} />
	<Route exact path='/links' element={<Links />} />
	<Route path='*' element={<Home/>} />
</Routes>
      </Router>
</main>
<Footer/>
</Container>
</ThemeProvider>
    </div>  
);
}
